import { Compliance1a } from '@/blocks/compliance/variants/compliance-1a';

const prefix = 'compliance';

const variants = {
    [`${prefix}_1a`]: Compliance1a,
};

export const Compliance = ({ ...block }) => {
    const Component = variants[block.variant];
    return Component ? <Component {...block} /> : null;
};
