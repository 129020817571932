import { Media } from '@/components/media';
import { Footnotes } from '@/components/footnotes';
import { RichText } from '@/components/rich-text';
import classNames from 'classnames';
import { getSizes } from '@/lib/utils/get-sizes';

export const Compliance1a = ({
    settings,
    description,
    media,
    footnotes,
    attr,
}) => {
    return (
        <section
            className={classNames('section', {
                ...settings,
            })}
            {...attr}
        >
            <div className="container">
                <div className="compliance">
                    {media && (
                        <>
                            <Media
                                wrapperClassName="compliance__image"
                                media={media}
                                disableCaption
                                sizes={getSizes({
                                    desktop: 6,
                                    tablet: 12,
                                })}
                            />
                        </>
                    )}
                    {description && (
                        <div className="compliance__content">
                            <RichText
                                className="compliance__description"
                                data={description}
                            />

                            {footnotes?.value && (
                                <Footnotes
                                    className={'mt-4'}
                                    footnotes={footnotes}
                                />
                            )}
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};
